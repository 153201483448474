import React, { useContext } from "react";

const PlatformUrlBaseContext = React.createContext();

export function getEntryPointUrlBase() {
  if (process.env.NODE_ENV !== "production") {
    return "";
  }

  const msBaseUrl = window._msBaseUrl;
  if (typeof msBaseUrl === "string") {
    return msBaseUrl;
  }

  if (window.location.pathname.match(/^\/components($|\/)/)) {
    return "/components";
  }

  return "";
}

export function useComponentPathPrefix() {
  return process.env.NODE_ENV === "production" ? "/components" : "";
}

export function PlatformUrlBaseProvider({ children }) {
  const values = {
    base:
      process.env.NODE_ENV === "production"
        ? ""
        : "https://localhost_www.mobsuccess.com",
    entryPointBase: getEntryPointUrlBase(),
  };
  return (
    <PlatformUrlBaseContext.Provider value={values}>
      {children}
    </PlatformUrlBaseContext.Provider>
  );
}

export function usePlatformUrlBase() {
  return useContext(PlatformUrlBaseContext).base;
}

export function useEntryPointUrlBase() {
  return useContext(PlatformUrlBaseContext).entryPointBase;
}
