import React from "react";

import { initSentry } from "@mobsuccess-devops/react-sentry";

import "./App.css";
import Hotjar from "./components/Hotjar";
import DefaultEntryPoint from "./entry-points/Default.js";

initSentry({
  dsn:
    "https://d5786d88f83242b5b544c0df5937aa49@o1028474.ingest.sentry.io/5995805",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "local",
});

function App() {
  return (
    <div className="App">
      <Hotjar />
      <DefaultEntryPoint />
    </div>
  );
}

export default App;
